jQuery.fn.extend({ // Adding a method to the base framework
  make_drop_down: function () {
    jQuery(this).click(function () {
      jQuery(this).toggleClass("closed");
      jQuery(this).siblings().not('.contextual-links-wrapper').toggle(400);
    });
  }
});

jQuery(document).ready(function ($) {

  var _popupLink = jQuery('#subscribe_popup');
  if(_popupLink.length > 0){
    var popStateCount = localStorage.getItem('popStateCount');
    var popStateTime = localStorage.getItem('popStateTime');    

    if(popStateCount < 3 && ((popStateTime + 60*60*24*7) < (+ new Date()))){
      localStorage.setItem('popStateTime', (+ new Date()));
      localStorage.setItem('popStateCount', popStateCount + 1);
      $('#subscribe_popup').click();
    }
  }

  $(".logo").dblclick(function () {
    document.location = '/user';
  });

  $(".field-group-accordion h3, #sidebar-first h2, .drop-down").make_drop_down();

  $(".show-large-image").click(function (event, el) {
    event.preventDefault();
    $("body").find(".zoomContainer").detach();
    var large_img_src = $(this).attr("src");
    var zoom_img_src = $(this).attr("data-zoom-image");
    var random_number = Math.random().toString().substring(2);
    var new_image = $("<img>").attr("src", large_img_src).attr("data-zoom-image", zoom_img_src).attr("id", "new-image-" + random_number);
    $(".field-name-asset-slideshow-navigation .view-header").html(new_image);
  });


  $(".field-name-empty-divider").click(function () {
    var parent = $(this).parents(".views-row");
    $(this).toggleClass("toggle");
    $(parent).find(".branch-details").toggleClass("toggle");
    $(parent).find(".branch-banking-details").toggleClass("toggle");
  });

  $("#logo-container").click(function (event) {
    document.location = '/';
  });

  //Adjust enuiry form "topic" field
  var enquiry_form = $('#auction-enquire form');

  //Adjust enquiry topic title
  if (enquiry_form != null) {
    auction_title_element = $('#edit-field-enq-auction-und-0-value', enquiry_form);
    auction_title = auction_title_element.val()
    auction_title_element.val(' ');

    //Add asset to enquiry title when clicking on enquiry title on auction
    // asset lists
    $('a[data-enquiretitle]').on('click', function () {
      asset_title = jQuery(this).data('enquiretitle')
      if (asset_title != null) {
        auction_title_element.val(auction_title + ' - ' + asset_title);
      }
      else {
        auction_title_element.val(auction_title);
      }

    });
  }

  //Forms
  $('.form-item-other-comment input').val('#67^89gjGh^57#6%0(3*Xd%');
  $("a[href='/node/'] img").unwrap("a");

  //Auction asset list
  auctionLots = $('.view-lots-on-auction');
  if(auctionLots.length > 0){
    $('._list', auctionLots).on('click', function(){
      auctionLots.removeClass('asset-list-grid');
      auctionLots.addClass('asset-list-list');

    });
    $('._grid', auctionLots).on('click', function(){
      auctionLots.addClass('asset-list-grid');
      auctionLots.removeClass('asset-list-list');
    });

    $(document).bind('cbox_complete', function(){
        $('#views-exposed-form-lots-on-auction-auction-lots-list .form-submit').on('click',function(){
          $('#cboxClose').click();
        });
    });
  }
});